<template>
  <ul class="menu-nav">


      <li
    aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      
      v-for="(item, index) in itemsMenu"
      :key="index"
    >
      <router-link
        :to="item.route"
        v-slot="{ href, navigate }"
        v-if="!item.subItems"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="text-white menu-icon"
            :class="{ [item.iconClass]: true }"
          ></i>
          <span class="menu-text">{{
            item.title
          }}</span>
        </a>
      </router-link>

      
           <a
       
             v-if="lista_permissoes_filial.ra_Empresa == item.permissao ||
             lista_permissoes_filial.ra_funcionario == item.permissao ||
             lista_permissoes_filial.ra_Usuario == item.permissao ||
             lista_permissoes_filial.ra_FinanPedidosReceber == item.permissao ||
             lista_permissoes_filial.ra_Imovel == item.permissao ||
             lista_permissoes_filial.ra_Contrato == item.permissao ||


             lista_permissoes_filial.ra_FinanCaixa == item.permissao

             "
     
     
       href="#" class="menu-link menu-toggle">
        
        <i class="text-white menu-icon" :class="{ [item.iconClass]: true }"></i>
        <span class="menu-text ">
          {{ item.title }}
        </span>
        <i class="text-white menu-arrow"></i>
      </a>
    
    <div class="menu-submenu menu-submenu-classic">
     <ul class="menu-subnav">
          <router-link
            :to="subItem.route"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-for="(subItem, index) in item.subItems"
            :key="index"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
           
              <a
            
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ subItem.title }} </span>
              </a>

            </li>
          </router-link>
        </ul>
      </div>
    </li>
    
  
  </ul>
</template>

<script>
import sideMenu from "@/core/router/sideMenu";

export default {
  name: "KTMenu",

  data() {
    return {
      itemsMenu: sideMenu,
      sede_id: 1,
    };
  },
  created() {
    this.$store.dispatch("sistema/listar_filiais_autorizadas");
  },
  watch: {
    sede_id: function () {
      this.$store.dispatch("vincularSede", this.sede_id);
    },
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

