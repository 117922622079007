export default [
  {
    title: "DashBoard",
    route: "/dashboard",
    permissao: "paginainicial",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    permissao: "ra_Empresa",
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "ra_Role",
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "ra_Empresa",
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "ra_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "ra_Perfil",
      },
    ],
  },

  {
    title: "RH",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Funcionários",
        route: "/gerenFuncionarios",
        permissao: "c_Usuario",
      },
    ],
  },
  {
    title: "Comercial",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Contas",
        route: "/contas",
        permissao: "ra_Usuario",
      },
      {
        title: "Aliquota",
        route: "/aliquota",
        permissao: "ra_Usuario",
      },
    ],
  },
  


  
  // {
  //   title: "Imoveis",
  //   route: null,
  //   permissao: "ra_Imovel",
  //   iconClass: "fas fa-home",
  //   subItems: [
  //     {
  //       title: "Imoveis",
  //       route: "/imovel",
  //       permissao: "ra_Imovel",
  //     },
  //   ],
  // },
  // {
  //   title: "Contratos",
  //   route: null,
  //   permissao: "ra_Contrato",
  //   iconClass: "fas fa-home",
  //   subItems: [
  //     {
  //       title: "Contratos",
  //       route: "/contrato",
  //       permissao: "ra_Contrato",
  //     },
  //   ],
  // },







 

 
];
