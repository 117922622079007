<template>
  <div>
    <form>
      <!--begin::Header-->
      <div
        class="
          d-flex
          align-items-center
          py-10
          px-8
          bgi-size-cover bgi-no-repeat
          rounded-top
        "
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
          <i class="flaticon2-shopping-cart-1 text-success" />
        </span>
        <h4 class="text-white m-0 flex-grow-1 mr-3">Saque Rápida</h4>
        <button
          type="button"
          @click="openModel()"
          class="btn btn-success btn-sm"
        >
          adicionar
        </button>
      </div>
      <!--end::Header-->

      <div class="scroll scroll-push">
        <perfect-scrollbar
          class="scroll"
          style="max-height: 30vh; position: relative"
        >
          <!--begin::Item-->
          <template v-for="(item, i) in lista_form">
            <div
              class="d-flex align-items-center justify-content-between p-8"
              v-bind:key="i"
            >
              <div class="d-flex flex-column mr-2">
                <a
                  href="#"
                  class="
                    font-weight-bold
                    text-dark-75
                    font-size-lg
                    text-hover-primary
                  "
                >
                  cliente: {{ item.cliente }}
                </a>
                <span class="text-muted">
                  {{ item.descricao }}
                </span>
                <div class="d-flex align-items-center mt-2">
                  <span class="font-weight-bold mr-1 text-dark-75 font-size-3">
                    {{ item.valor | formatMoney}}
                  </span>

                  <a href="#" class="btn btn-xs btn-light-success btn-icon">
                    <i class="ki ki-wrench icon-xs"></i>
                  </a>
                </div>
              </div>
              <span href="#" class="flex-shrink-0 font-weight-700">
                {{  item.data | formatDBDateToLocale}}
              </span>
            </div>
          </template>
          <!--end::Item-->
          <div class="separator separator-solid"></div>
        </perfect-scrollbar>
      </div>

      <!--begin::Summary-->
      <div class="p-8">
        <div class="d-flex align-items-center justify-content-between mb-7">
          <span class="font-weight-bold text-muted font-size-sm mr-2"
            >Total de saque</span
          >
          <span class="font-weight-bolder text-primary text-right">{{
            soma
          }}</span>
        </div>
        <div class="text-right">
          <button @click="confirm('cria')" type="button" class="btn btn-primary text-weight-bold">
            Salvar
          </button>
        </div>
      </div>
      <!--end::Summary-->
    </form>
    <v-dialog v-model="modalAcesso" max-width="900">
      <template>
        <v-card elevation="8">
          <v-card-title>
            <h3 class="mb-0">Formulario</h3>
          </v-card-title>
          <v-divider></v-divider>
          <div class="card-body">
            <div class="row form-group">
              <div class="col-md-5">
                <label>Usuarios:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_contas"
                  placeholder="Selecione a Conta..."
                  v-model="form.contas_usuario_id"
                  @input="onInput"
                />
              </div>
              <div class="col-md-3">
                <label>Valor:*</label>
                <input
                  required=""
                  type="text"
                  class="form-control"
                  v-model="valor"
                  placeholder="Valor..."
                  v-mask="variableWithMask2"
                />
              </div>
              <div class="col-md-9">
                <label>Mensagem:*</label>
                <input
                  required=""
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Descrição..."
                />
              </div>
              <div class="col-md-3">
                <label>Tipo Ação:*</label>
                <select
                  v-model="form.tipo"
                  class="form-control"
                  placeholder="Selecione"
                >
                  <option value="0" disabled>Selecione</option>
                  <option value="1">Saque</option>
                  <!-- <option value="2">Deposito</option> -->
                </select>
              </div>
              <div v-show="form.tipo" class="col-md-4">
                <label
                  >Data {{ form.tipo == 1 ? "Saque" : "deposito" }}:*</label
                >
                <input
                  required=""
                  type="date"
                  class="form-control"
                  id="form_control_1"
                  v-model="form.data"
                  placeholder="Data de nascimento..."
                />
              </div>
              <div v-if="form.tipo == 2" class="col-md-5">
                <InputFile class="pt-6" text="Comprovante" ref="comprovante" />
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-md-2 text-center">
                <v-btn
                  color="primary"
                  elevation="2"
                  :loading="verif"
                  @click.prevent="add"
                  class="btn btn-primary"
                  :disabled="verif"
                  >salvar</v-btn
                >
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import dateFormat from "@/core/mixins/dateFormat";
import moneyFormat from "@/core/mixins/moneyFormat";


export default {
  name: "KTDropdownMyCart",
  mixins: [dateFormat,moneyFormat],
  data() {
    return {
      modalAcesso: false,
      verif: false,
      valor: null,
      variableWithMask2: "",
      soma: 0,
      form: {
        descricao: null,
        valor: null,
        cliente: null,
        tipo: 0,
        contas_usuario_id: null,
      },
      lista_form: [],
      list: [
        {
          title: "iBlender",
          desc: "Best kichen badge in 2020",
          price: "$ 350",
          quantity: "5",
          img: process.env.BASE_URL + "media/stock-600x400/img-1.jpg",
        },
        {
          title: "SmartCleaner",
          desc: "Smart tool for cooking",
          price: "$ 650",
          quantity: "4",
          img: process.env.BASE_URL + "media/stock-600x400/img-2.jpg",
        },
        {
          title: "CameraX",
          desc: "Professional camera for edge cutting shots",
          price: "$ 150",
          quantity: "3",
          img: process.env.BASE_URL + "media/stock-600x400/img-3.jpg",
        },
        {
          title: "3DPrinted",
          desc: "Manufactoring unique objects",
          price: "$ 1450",
          quantity: "7",
          img: process.env.BASE_URL + "media/stock-600x400/img-4.jpg",
        },
      ],
    };
  },

  watch: {
    valor: function () {
      let pa = "#";
      for (let i = 0; i < this.valor.length; i++) {
        if (i > 6) {
          pa = pa + "#";
        }
      }
      this.variableWithMask2 = "R$" + pa + "#,##";
      console.log(this.variableWithMask2);
      this.form.valor = parseFloat(
        this.valor.replace("R$", "").replace(",", ".")
      );
    },
  },
  computed: {
    // lista_contas() {
    //   return this.$store.state.contas.lista_contas;
    // },
    lista_contas() {
      let contas = Object.values(this.$store.state.contas.lista_contas);

      let data = [];
      for (let i = 0; i < contas.length; i++) {
        data.push({ id: contas[i].id, label: contas[i].cliente });
      }
      return data;
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem Certeza?",
        message:
          tipo == "cria"
            ? `Essa Solicitação de ` +
              (this.form.tipo == 1 ? `saque ` : `deposito `) +
              ` no saldo do cliente no sistema?`
            : "" ,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
          }
        },
      });
    },
    async create() {
      this.verif = true;
    
   
      await this.$store.dispatch("movimentacao/create_mov_lote", this.lista_form);
      this.verif = false;
      this.fireAlert({ ...this.mensagem_alert });
    },
    async add() {
      this.lista_form.push(this.form);

      this.form = {
        descricao: null,
        valor: null,
        // arquivos_id: null,
        tipo: 0,
        contas_usuario_id: null,
      };
this.valor = null
      this.lista_form.forEach((element) => {
        this.soma += element.valor;
      });
      this.modalAcesso = false;
    },
    async createMovimentacao() {
      if (this.$refs.arquivoAlvaraInput) {
        this.form.arquivos_id = await this.$refs.comprovante.submit();
      }
    },
    async openModel() {
      this.modalAcesso = true;
    },
    onInput(value) {
      let usuarios = Object.values(this.$store.state.contas.lista_contas);
      usuarios = usuarios.filter((use) => use.id === value);
      this.form.cliente = usuarios[0].cliente;
    },
  },
};
</script>
