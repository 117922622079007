export default [

    {
        title: "Perfil",
        botoes: [
            {
                title: "Criar",
                route: "/createPerfil",
                permissao: "c_Perfil",
            },
            {
                title: "Listar",
                route: "/perfil",
                permissao: "ra_Perfil",
            },
        ],
    },

    {
        title: "Permissoes",
        botoes: [
            {
                title: "Criar",
                route: "/createRule",
                permissao: "c_Role",
            },
            {
                title: "Listar",
                route: "/permissoes",
                permissao: "ra_Role",
            },
        ]
    },
    {
        title: "Empresa",
        botoes: [
            {
                title: "Criar",
                route: "/createEmpresa",
                permissao: "ra_empresa",
            },
            {
                title: "Listar",
                route: "/empresa",
                permissao: "ra_empresa",
            },
        ]
    },
    {
        title: "Filial",
        botoes: [
            {
                title: "Criar",
                route: "/createFilial",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/filial",
                permissao: "ra_Filial",
            },
        ]
    },
    {
        title: "Contas",
        botoes: [
            {
                title: "Criar",
                route: "/createContas",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/contas",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Aliquotas",
        botoes: [
            {
                title: "Criar",
                route: "/createAliquota",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/aliquota",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Funcionarios",
        botoes: [
            {
                title: "Criar",
                route: "/createFuncionario",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/gerenFuncionarios",
                permissao: "ra_Usuario",
            },
        ],
    },
    
    // {
    //     title: "Imoveis",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createImovel",
    //             permissao: "c_Imovel",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/imovel",
    //             permissao: "ra_Imovel",
    //         },
    //     ],
    // },
    // {
    //     title: "Contratos",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createContrato",
    //             permissao: "c_Contrato",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/contrato",
    //             permissao: "ra_Contrato",
    //         },
    //     ],
    // },


  

    


  




];
